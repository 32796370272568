.popup {
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: visibility 0.5s, opacity 0.5s linear; 
}

@media screen and (max-width: 640px){
  .popup {
    gap: 13px;
    justify-content: flex-start;
    padding-top: 85px;
  }
}