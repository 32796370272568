.popup__title {
    margin: 0;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
}

@media screen and (max-width: 640px){
    .popup__title {
        font-size: 18px;
        line-height: 22px;
    }
  }
