.profile__name {
    color: #fff;
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    margin: 0;
    grid-area: name;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 500px;
}


@media screen and (max-width: 968px){
    .profile__name {  
        font-size: 22px;
        line-height: 27px;
        max-width: 198px;
        margin-top: 26.25px;
    }
}