.popup__container {
  display: grid;
  z-index: 1;
  width: 430px;
  border-radius: 10px;
  background-color: #fefefe;
  padding: 34px 36px 36.5px;
  margin: 0;
  box-sizing: border-box;
}


@media screen and (max-width: 640px){
  .popup__container {
    width: 282px;
    padding: 25px 22px;
  }
}
