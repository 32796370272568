.profile__about-me {
    color: #fff;
    margin: 0;
    grid-area: about;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 500px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

@media screen and (max-width: 968px){
    .profile__about-me  {
        text-align: center;
        margin-bottom: 36px;
        font-size: 14px;
        line-height: 17px;
        max-width: 282px;
    }
}