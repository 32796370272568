.header__logo {
    width: 173px;
    height: 33px;
}

@media screen and (max-width: 968px){
    .header__logo {
        margin-left: 27px;
        width: 126px;
        height: 24px;
    }
}