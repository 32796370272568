.popup__submit-btn {
    margin-top: 48px;
    margin-bottom: 0;
    background-color: #000;
    color: #fff;
    height: 50px;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    border: none;
    width: 100%;
  }

  @media screen and (max-width: 640px){
    .popup__submit-btn  {
      margin-top: 46px;
    }
  }