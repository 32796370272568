.authe__text {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .authe__text {
    font-size: 12px;
    line-height: 15px;
  }
}
