.popup__input-text {
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #000;
    border-width: 0 0 2px;
    border-color: rgba(0, 0, 0, .2);
    padding-bottom: 13px;
    outline: 0;
    width: 100%;
    
  }

  .popup__input-text:nth-child(1) {
    padding-top: 76px;
  }

  .popup__input-text:nth-child(3) {
    padding-top: 30px;
  }

  
  @media screen and (max-width: 640px){
    .popup__input-text  {
      padding-bottom: 9px;
    }
  }
  
