.popup__img {
    max-width: 75vw;
    max-height: 75vh;
    margin-right: 35px;
}
@media screen and (max-width: 640px){
    .popup__img {
        margin-right: 25px;
    }
  }
