.footer__author {
    color: #545454;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}


@media screen and (max-width: 640px){
    .footer__author {
        margin: 0 16px;
        font-size: 14px;
        line-height: 17px;
    }
}