.authe__btn {
    width: 100%;
    height: 50px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em; 
    text-align: center;
    text-decoration: none;
}