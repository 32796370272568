.authe__welcome {
  color: #fff;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 50px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0em;
}

@media only screen and (max-width: 425px) {
  .authe__welcome {
    font-size: 20px;
    line-height: 24px;
  }
}
