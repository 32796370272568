.header {
    padding-top: 45px;
    padding-bottom: 41px;
    border-bottom: 1px solid #545454B3;
    max-width: 880px;
    margin: auto;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 968px){
    .header {
        padding-top: 28px;
        padding-bottom: 32px;
        max-width: 581px;
        margin: auto;
    }
}

@media screen and (max-width: 640px){
    .header {
        max-width: 320px;
    }
}