.gallery__item-footer {
    display: flex;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 21px;
    overflow: hidden;

}