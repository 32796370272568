.tooltip__text {
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .tooltip__text {
    font-size: 20px;
    line-height: 24px;
  }
}
